export const headers = [
  {
    label: 'last_name',
    key: 'last_name'
  },
  {
    label: 'first_name',
    key: 'first_name'
  },
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'Phone number country code',
    key: 'phone_number.country_code'
  },
  {
    label: 'Phone number',
    key: 'phone_number.number'
  },
  {
    label: 'role',
    key: 'role'
  },
  {
    label: 'Address street number',
    key: 'address.number'
  },
  {
    label: 'Address street name',
    key: 'address.street'
  },
  {
    label: 'Address additional information',
    key: 'address.additional'
  },
  {
    label: 'Address postal code',
    key: 'address.postal_code'
  },
  {
    label: 'Address city',
    key: 'address.city'
  },
  {
    label: 'Address country',
    key: 'address.country'
  }
];
