import dayjs from 'dayjs';
import 'dayjs/locale/fr';

dayjs.locale('fr');

export const formatNotificationDate = (date) => {
  const now = dayjs();
  const createdAt = dayjs(date);
  const yesterday = now.subtract(1, 'day').startOf('day');
  const isToday = now.isSame(date, 'day');
  const isYesterday = yesterday.isSame(date, 'day');

  let formattedDate;

  if (isToday) {
    formattedDate = `Aujourd'hui à ${createdAt.format('HH:mm')}`;
  } else if (isYesterday) {
    formattedDate = `Hier à ${createdAt.format('HH:mm')}`;
  } else {
    formattedDate = createdAt.format('DD MMMM YYYY');
  }

  return formattedDate;
};
