import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Steps, Button, message as successMessage, Tooltip } from 'antd';
import { ContentCustom } from '../../components/ContentCustom/ContentCustom';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import CreateUpdateFormWorkFlow from './CreateUpdateFormWorkFlow';
import { validateWorkflow } from './utils';
import WFLMap from './WFLMap';

// TODO : Rendre le controle de conformité du process visible
// TODO : Gérer les subprocesses

export const CreateUpdateWorkflow = ({ purpose }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [workflow, setWorkflow] = useState({});
  const [popupContainer, setPopupContainer] = useState(null);

  const [elements, setElements] = useState([]);
  const isLoaded = !(elements === []);

  const getWorkflow = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/workflows/${id}` });
      setWorkflow(data);
      setElements(data.elements);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createWorkflow = async (body) => {
    try {
      await dispatchAPI('POST', { url: `/workflows/`, body });
      successMessage.success('Workflow correctement enregistré');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const saveWorkflow = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/workflows/${id}`,
        body
      });
      successMessage.success('Workflow correctement enregistré');
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    if (purpose === 'edit') await getWorkflow();
    setIsFieldsLoading(false);
  }, [elements]);

  const finishCreateUpdate = () => {
    const newWFL = { ...workflow, elements };
    if (purpose === 'edit') {
      saveWorkflow(newWFL);
    } else {
      createWorkflow(newWFL);
    }
    navigate(-1);
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      'Le statut du workflow est : ',
      workflow.status,
      '!validateWorkflow(elements) : ',
      !validateWorkflow(elements),
      'workflow.status === "VALIDATED" : ',
      workflow.status === 'VALIDATED'
    );
  }, [workflow.status]);

  useEffect(() => {
    getSelectOptions();
  }, []);

  const stepToShow = () => {
    switch (currentStep) {
      case 0:
        return (
          <CreateUpdateFormWorkFlow
            isFieldsLoading={isFieldsLoading}
            workflow={workflow}
            updateWorkflow={setWorkflow}
            nextStep={() => setCurrentStep(currentStep + 1)}
          />
        );
      case 1:
        return (
          <div style={{ height: '100%', margin: 10, padding: 5 }}>
            {isLoaded && (
              <WFLMap
                workflowData={workflow}
                workflowId={id}
                elements={elements}
                setElements={setElements}
              />
            )}
            <Button
              block
              type="primary"
              disabled={
                !validateWorkflow(elements) && workflow.status === 'VALIDATED'
              }
              onClick={finishCreateUpdate}
            >
              {!validateWorkflow(elements) &&
              workflow.status === 'VALIDATED' ? (
                <Tooltip
                  title="Vous ne pouvez pas enregistrer un workflow non valide avec le statut 'VALIDATED'"
                  placement="top"
                  getPopupContainer={() => popupContainer}
                  open
                >
                  <span>Enregistrer</span>
                </Tooltip>
              ) : (
                <span>Enregistrer</span>
              )}
            </Button>
            <div
              ref={setPopupContainer}
              style={{ position: 'relative', zIndex: 1 }}
            />
          </div>
        );
      default:
        return (
          <CreateUpdateFormWorkFlow
            workflow={workflow}
            updateWorkflow={setWorkflow}
          />
        );
    }
  };

  return (
    <ContentCustom>
      {isFieldsLoading ? (
        <Spin />
      ) : (
        <>
          <Steps
            current={currentStep}
            items={[
              {
                title: 'Informations basiques du workflow',
                onClick: () => setCurrentStep(0)
              },
              {
                title: 'Définition du workflow',
                onClick: () => setCurrentStep(1)
              }
            ]}
          />
          {stepToShow(currentStep)}
        </>
      )}
    </ContentCustom>
  );
};

CreateUpdateWorkflow.propTypes = {
  purpose: PropTypes.string.isRequired
};
